import { Routes, Route } from "react-router-dom";
import Home from "./Views/home";
import "bootstrap/dist/css/bootstrap.min.css";
import Policy from "./Views/policy";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="policy.html" element={<Policy />} />
        <Route path="politics.html" element={<Policy />} />
        <Route path="policy" element={<Policy />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
  );
}

export default App;
