import React from "react";

const Policy = () => {
  return (
    <div>
      <header className="App-header">
        <h1 className="mt-4">Політика конфіденційності</h1>
        <p style={{ fontStyle: "italic" }}>
          Дата набрання чинності: 4 червня 2022 року
        </p>
        <h2>ПРЕАМБУЛА</h2>
        <p className="mt-4 mb-4 mx-5">
          ВИКОРИСТОВУЮЧИ САЙТ RADIODIM.RV.UA, ВИ ПІДТВЕРДЖУЄТЕ, ЩО ВАМ ВІДОМО
          ПРО ЦІЛІ ТА СПОСОБИ ОТРИМАННЯ І ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ, І ВИ ДАЄТЕ
          СВОЮ ЗГОДУ НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ І НАДАННЯ ЇХ ТРЕТІМ ОСОБАМ
          ВІДПОВІДНО ДО ПОЛОЖЕНЬ ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ.
          <br />
          <br />
          УСІ ДАНІ, ЩО ВИ НАДАЄТЕ, Є НЕОБХІДНОЮ УМОВОЮ ДЛЯ УКЛАДЕННЯ УГОДИ ПРО
          УМОВИ КОРИСТУВАННЯ САЙТОМ RADIODIM.RV.UA І МОБІЛЬНИМ ДОДАТКОМ
          РАДІОДІМ.
          <br />
          <br />
          Ця Політика конфіденційності розроблена 04.06.2022 відповідно до
          чинного законодавства України, в тому числі, але не виключно, Закону
          України «Про захист персональних даних» від 01 червня 2010 року №
          2297-VI, що стосується Користувачів Платформи RADIODIM.RV.UA та
          встановлює порядок отримання, збору, накопичення, зберігання,
          оброблення, використання, забезпечення захисту та розкриття
          Персональних даних Користувачів Платформи.
          <br />
          <br />
          Посилання на слова "ми", "наш" або "нас" (або аналогічні за змістом
          слова) означає Платформу RADIODIM.RV.UA і додаток РАДІОДІМ зокрема.
          <br />
          <br />
          Посилання на слова "ви" або "ваш" (або аналогічні слова за змістом)
          означають нашого Користувача - Клієнта.
          <br />
          <br />
          Ця Політика конфіденційності встановлює те, як ми працюємо з певними
          Персональними даними Клієнтів.
          <br />
          <br />
          Ми можемо змінювати цю Політику конфіденційності, тому періодично
          перевіряйте цю сторінку, щоб переконатися, що вас влаштовують зміни.
          Щоразу оновивши інформацію ми будемо публікувати дату внесення змін.
          <br />
          <br />
          Ми робимо все можливе для дотримання всіх правил, що стосуються
          обробки і зберігання Персональних даних, як GDPR, так і українського
          законодавства, пов'язаного з Персональними даними. Положення цієї
          Політики конфіденційності складені відповідно до законодавства
          України, європейського законодавства в галузі дотримання Персональних
          даних (Регламент (ЄС) 2016/679 General Data Protection Regulation
          ("GDPR")), директиви про конфіденційність електронних комунікацій
          (Directive on Privacy and Electronic Communications - ePrivacy
          Directive), а також приведені у відповідність, дотримуються та не
          суперечать вимогам законодавства США у сфері захисту Персональних
          даних, зокрема California Consumer Privacy Act (CCPA), федеральному
          закону про конфіденційність даних (Federal Privacy Act); закону про
          конфіденційність електронної комунікації (Electronic Communications
          Privacy Act); закону про захист споживачів онлайн (Online Consumer
          Protection Act); закону про захист конфіденційності дітей в Інтернеті
          (Children's Online Privacy Protection Act - COPPA), а також приведені
          у відповідність, дотримуються та не суперечать вимогам законодавства
          Канади у сфері захисту Персональних даних, зокрема закону про
          конфіденційність персональних даних (Personal Information Protection
          and Electronic Documents Act - PIPEDA); закону про конфіденційність
          дітей в Інтернеті (Child Online Privacy Protection Act - COPPA);
          закону про канадські анти-спам-закони (CASL).
          <br />
          <br />
        </p>
        <h3 className="mt-5 mb-2">ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h3>
        <p className="mt-2 mb-4 mx-5">
          <b>Дані</b> - будь-яка інформація, що прямо чи опосередковано
          відноситься до конкретного Користувача. До даних відносяться: ім'я,
          прізвище, адреса електронної пошти, номер телефону, регіон проживання,
          часовий пояс, мова інтерфейсу, дані про пристрої (комп'ютер, тип
          браузера, операційна система мобільного пристрою), що використовуються
          Користувачем, повідомлення (інформація, що міститься у листуванні) між
          Користувачами, а також у листуванні з Адміністрацією Платформи), інша
          інформація, за допомогою якої здійснюється комунікація, яка за
          бажанням Користувача надається їм за допомогою реєстраційної форми
          та/або при заповненні власного профілю на Платформі, при написанні
          повідомлення або відгуку, або інформації, що отримана іншим способом.
          <br />
          <br />
          <b>Обробка</b> - будь-яка операція з Персональними даними,
          автоматизована чи ні, така як збір, запис, організація,
          структурування, зберігання, адаптація або зміна, пошук, ознайомлення,
          використання, розкриття шляхом передачі, розповсюдження або інше
          надання доступу, синхронізація або поєднання, обмеження чи знищення.
          Під Обробкою Персональних даних розуміються будь-які дії з
          Персональними даними в тому числі, але не виключно: надання доступу,
          розповсюдження, збирання, систематизація, зберігання, накопичення,
          запис, передача, блокування, видалення, уточнення, оновлення та/або
          зміна, знеособлення та інші способи використання персональних даних
          Платформою.
          <br />
          <br />
          <b>Персональні дані</b> — інформація, що стосується вас, окремо або у
          поєднанні з іншими частинами інформації, що дозволяє особі, яка збирає
          та обробляє інформацію, ідентифікувати вас як особистість.
          <br />
          <br />
          <b>Суб'єкт даних</b> – фізична особа або особа, яку можна
          ідентифікувати, особисті дані якої обробляються оператором,
          відповідальним за обробку; позначає вас як Користувача Платформи.
          <br />
          <br />
          <b>Згода</b> — вільна, конкретна, поінформована та однозначна вказівка
          за бажанням Суб'єкта даних, за допомогою якого він або вона, заявою
          або чіткою позитивною дією, позначає згоду на Обробку його
          Персональних даних.
        </p>
        <h3 className="mt-5 mb-2">1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h3>
        <p className="mt-2 mb-4 mx-5">
          1.1 Платформа не здійснює обробку Даних, що належать до расової,
          національної приналежності, політичних поглядів, релігійних та інших
          переконань, членства у громадських організаціях.
          <br />
          1.2 Інформація, що характеризує фізіологічні особливості користувачів,
          на основі яких можна встановити їх особу, також не обробляється.
        </p>
        <h3 className="mt-5 mb-2">2. ЯКІ ДАНІ МИ ЗБИРАЄМО І ОБРОБЛЯЄМО?</h3>
        <p className="mt-2 mb-4 mx-5">
          2.1 Ми можемо збирати різні типи даних, як безпосередньо отримані від
          вас при реєстрації (Стаття 13 GDPR), так і отримані автоматично, через
          ваш пристрій (наприклад, персональний комп'ютер, ноутбук, мобільний
          телефон), коли ви користуєтеся Сервісом Платформи (Стаття 13-14)
          GDPR). Відповідно до принципу "мінімізації даних" (Стаття 5(1)(c)
          GDPR), ми збираємо та обробляємо лише ті Персональні дані, які суворо
          необхідні для надання вам Послуг RADIODIM.RV.UA.
          <br />
          2.2 RADIODIM.RV.UA може збирати Дані, наведені нижче:
          <br />
          2.2.1 Профіль Клієнта. RADIODIM.RV.UA збирає та обробляє Персональні
          дані, надані вами під час реєстрації та подальшого редагування вашого
          профілю. Для цілей реєстрації ви вказуєте реєстраційні дані: ім'я
          користувача та пароль, прізвище та електронну адресу, також ви можете
          редагувати свій Профіль та доповнювати його шляхом надання інформації
          за власним бажанням. Додаткова інформація може допомогти вам отримати
          цільові послуги. У той же час ви можете не надавати додаткову
          інформацію, яка не потрібна під час реєстрації.
          <br />
          2.2.2 Інформація, розміщена Користувачами за результатами їх
          обслуговування. RADIODIM.RV.UA збирає та обробляє інформацію, таку як
          відгуки, коментарі, звернення до Служби підтримки, скарги, претензії
          та інші звернення.
          <br />
          2.2.3 Інформація про транзакції, які здійснюються за допомогою
          платформи RADIODIM.RV.UA. Ми безпосередньо не обробляємо інформацію
          про вашу кредитну/дебетову картку, натомість ми використовуємо
          сторонній платіжний сервіс, щоб виставляти вам рахунки для оплати
          онлайн. Ми не зберігаємо вашу платіжну інформацію на наших серверах,
          таку як ідентифікатор, дату та час транзакції.
        </p>
        <h3 className="mt-5 mb-2">
          3. ДЛЯ ЯКИХ ЦІЛЕЙ МИ ЗБИРАЄМО І ОБРОБЛЯЄМО ВАШІ ДАНІ?
        </h3>
        <p className="mt-2 mb-4 mx-5">
          3.1 RADIODIM.RV.UA збирає та обробляє Персональні дані для наступних
          цілей:
          <br />
          3.1.1 Ми обробляємо ваші персональні дані, для надання доступу до
          наших сервісів. Ми використовуємо ваші персональні дані при
          реєстрації, щоб допомогти замовити/надати послугу, домовитися про
          зустріч онлайн/офлай.
          <br />
          3.1.2 Ми обробляємо певну платіжну інформацію для дотримання чинного
          законодавства у галузі фінансів та бухгалтерського обліку. Ми також
          можемо обробляти дані, зібрані на Платформі, у зв'язку з юридичними
          запитами, які ми отримуємо від вас або будь-якої іншої компетентної
          особи та/або органу, і ми можемо використовувати цю інформацію для
          здійснення, захисту наших законних інтересів.
          <br />
          3.1.3 Ми можемо використовувати Дані, у разі виникнення суперечок та
          інших питань, пов'язаних із Сервісами, (у тому числі комунікації) для
          вирішення таких питань та реагування на будь-які скарги чи запити.
          <br />
          3.1.4 Ми використовуємо контактні Дані Користувачів та платіжну
          інформацію для встановлення, підтримки та ведення відносин з
          Користувачами, необхідними для Надання Сервісів. Якщо Користувач не
          надасть необхідні особисті дані, ми можемо виявитися не в змозі
          виконати власні зобов'язання перед Користувачами..
          <br />
          3.1.5 Певна інформація, як ваша платіжна інформація та ваша поведінка
          на Платформі, підлягають моніторингу з боку RADIODIM.RV.UA, з метою
          запобігання шахрайській діяльності на Платформі та для того, щоб
          допомогти вам керувати Профілем.
          <br />
          3.1.6 Певні Сервіси Платформи вимагають доступу до компонентів вашого
          пристрою, зокрема до сховища. При зверненні до цього Сервісу
          з'являється спливаюче вікно із запитом дозволити Платформі доступ до
          відповідного компоненту пристрою. Ви можете налаштувати дозволи,
          наявні в установках пристрою. Якщо ви забороните такий доступ, ці
          Сервіси Платформи, можливо, працюватимуть не так, як передбачено.
          <br />
          3.2 Якщо Ви не бажаєте надавати запитувані нами Персональні дані для
          зазначених вище цілей, Ви можете не отримати доступ до Сервісів
          Платформи.
          <br />
          3.3 RADIODIM.RV.UA не збирає і не обробляє ваші персональні дані, якщо
          ви не дасте явної згоди на це.
        </p>
        <h3 className="mt-5 mb-2">
          4. З КИМ МИ МОЖЕМО ДІЛИТЬСЯ ВАШИМИ ПЕРСОНАЛЬНИМИ ДАНИМИ?
        </h3>
        <p className="mt-2 mb-4 mx-5">
          4.1 RADIODIM.RV.UA не продає, не передає та не розкриває Дані
          Користувачів, за винятком випадків, передбачених цією Політикою
          конфіденційності. Ми ніколи не збираємо і не обробляємо ваші особисті
          дані без пояснення та попередження і без вашої згоди.
          <br />
          4.2 У випадках, коли відсутнє рішення Європейської комісії, що
          підтверджує адекватний рівень захисту даних згідно зі ст. 45(1) GDPR,
          RADIODIM.RV.UA використовує стандартні положення про захист даних,
          прийняті Європейською комісією згідно зі ст. 46(2)(c) GDPR, щоб
          забезпечити відповідні гарантії ваших прав та Персональних даних у
          разі доступу третьої сторони або іншої передачі даних.
          <br />
          4.3 RADIODIM.RV.UA може містити посилання на сторонні веб-сайти,
          плагіни та програми. Натискання на ці посилання або включення цих
          підключень може дозволити третім особам збирати або надсилати дані про
          вас. Коли ви залишаєте RADIODIM.RV.UA, ми рекомендуємо вам
          ознайомитися з політикою конфіденційності кожного веб-сайту/програми,
          яку ви відвідуєте.
          <br />
          4.4 Ви погоджуєтесь, що будь-яка інформація, що надається через
          Сервіси, може бути доступна RADIODIM.RV.UA.
        </p>
        <h3 className="mt-5 mb-2">
          5. ДЕ ЗБЕРІГАЮТЬСЯ І ЯК ДОВГО ОБРОБЛЯЮТЬСЯ ПЕРСОНАЛЬНІ ДАНІ?
        </h3>
        <p className="mt-2 mb-4 mx-5">
          5.1 Відповідно до статті 5 (1)(b)(c)(e) GDPR, Ми дотримуємося
          принципів "обмеження мети", "мінімізації даних", "обмеження
          зберігання", і тому RADIODIM.RV.UA збирає, зберігає та іншим чином
          обробляє лише таку інформацію, яка необхідна для забезпечення наших
          законних інтересів та для виконання юридичного зобов'язання протягом
          періоду, необхідного для задоволення наших законних інтересів.
          <br />
          5.2 Щоб визначити належний термін зберігання персональних даних, ми
          враховуємо обсяг, характер і конфіденційність даних, потенційний ризик
          заподіяння шкоди від несанкціонованого використання або розкриття
          ваших персональних даних, цілі, для яких ми обробляємо персональні
          дані, а також чи можемо досягти цих цілей за допомогою інших засобів і
          застосовних вимог законодавства.
          <br />
          5.3 Якщо нам не потрібно обробляти ваші дані для цілей, викладених у
          цій Політиці конфіденційності, ми видалимо ваші персональні дані з
          наших систем. Однак ми можемо зберігати Ваші Дані довше, якщо не
          зможемо видалити їх з юридичних або технічних причин, у такому разі ми
          подбаємо про те, щоб Ваша конфіденційність була захищена і Ваші
          Персональні дані використовувалися тільки для цілей в межах
          обумовлених цією Політикою конфіденційності.
          <br />
          5.4 Період, протягом якого ми зберігаємо вашу особисту інформацію,
          необхідну для дотримання вимог та правових норм, варіюється і залежить
          від характеру юридичних зобов'язань та претензій у кожному конкретному
          випадку.
          <br />
          5.5 Ви маєте право видалити свій Профіль після такого запиту, він буде
          назавжди видалений з наших активних систем, серверів та резервних
          копій протягом 30 (тридцяти) днів. При видаленні профілю, всі ваші
          персональні дані також будуть видалені з Платформи, протягом 180 (ста
          вісімдесяти) днів.
        </p>
        <h3 className="mt-5 mb-2">6. НАШІ ЗАХОДИ БЕЗПЕКИ</h3>
        <p className="mt-2 mb-4 mx-5">
          6.1 Відповідно до статті 5(1)(d)(e)(f) GDPR, Ми дотримуємося принципів
          "точності", "обмеження зберігання", а також "цілісності та
          конфіденційності".
          <br />
          6.2 Всі персональні дані зберігаються у наших сторонніх обробників на
          захищених серверах відповідно до сучасних вимог інформаційної безпеки.
          Ми використовуємо рекомендовані галузеві практики для забезпечення
          безпеки доступу до таких даних.
          <br />
          6.3 Ми використовуємо відповідний рівень технічних та організаційних
          заходів для запобігання випадковому або незаконному знищенню, втрати,
          зміни, несанкціонованого розкриття або доступу до Персональних даних
          на Платформі.
          <br />
          6.4 У разі порушення зберігання даних (наприклад, витоку) ми
          зобов'язуємося провести розслідування, своєчасно повідомити
          компетентний орган захисту даних, оцінити збитки та повідомити
          результати розслідування всім Користувачам, чиї Персональні дані були
          порушені.
        </p>
        <h3 className="mt-5 mb-2">
          7. ВАШІ ПРАВА ЩОДО ВАШИХ ПЕРСОНАЛЬНИХ ДАНИХ
        </h3>
        <p className="mt-2 mb-4 mx-5">
          7.1 Відповідно до статті 5(1)(a)(d) GDPR, RADIODIM.RV.UA
          зобов'язується дотримуватися принципів "законності, справедливості та
          прозорості", а також "точності".
          <br />
          7.2 Відповідно до положень Закону України «Про захист персональних
          даних», GDPR а також CCPA, Ми зобов'язуємося дотримуватися ваших прав.
          Серед них ви маєте право:
          <br />
          7.2.1 доступу до Персональних даних згідно зі ст. 15 GDPR - це
          дозволяє вам отримати доступ або отримати копію ваших особистих даних,
          які ми зберігаємо та перевірити, чи законно ми їх обробляємо;
          <br />
          7.2.2 виправлення Персональних даних згідно зі ст. 16 GDPR - якщо ви
          вважаєте, що будь-яка інформація про вас, яку ми маємо, є неточною або
          неповною, ви маєте право вимагати, щоб ми виправили неточну особисту
          інформацію про Вас;
          <br />
          7.2.3 видалення персональних даних згідно зі ст. 17 GDPR; CCPA - ви
          можете вимагати, щоб ми видалили ваші персональні дані, якщо ви
          вважаєте, що:
          <br />
          7.2.3.1 нам більше не потрібно обробляти ваші персональні дані для
          цілей, для яких вони були надані;
          <br />
          7.2.3.2 ми запросили ваш дозвіл на обробку ваших персональних даних, і
          ви хочете відкликати свою Згоду.
          <br />
          7.2.4 обмеження Опрацювання Персональних даних згідно зі ст. 18 GDPR -
          ви маєте право запросити обмеження обробки ваших персональних даних.
          Це дозволяє попросити нас обмежити обробку ваших персональних даних,
          якщо:
          <br />
          7.2.4.1 дані вказані некоректно;
          <br />
          7.2.4.2 Профіль використовувався незаконно, але ви не бажаєте, щоб ми
          його видаляли;
          <br />
          7.2.4.3 Профіль більше не актуальний, але ви хочете, щоб ми зберегли
          його для використання у можливих судових позовах.
          <br />
          7.2.4.4 Зверніть увагу, що якщо ви попросите нас обмежити Обробку
          Персональних даних RADIODIM.RV.UA доведеться призупинити надання
          Сервісів Платформи.
          <br />
          7.2.5 перенесення Персональних даних згідно зі ст. 20 GDPR; CCPA - ви
          маєте право отримати Персональні дані, які надали нам у текстовому
          форматі. Ви також можете попросити нас надати їх третій стороні, якщо
          це технічно можливо. Ми не несемо відповідальності за використання
          такою третьою стороною інформації про ваш профіль, яка буде
          регулюватися їхньою угодою з вами та будь-якою заявою про
          конфіденційність, яку вони вам надали;
          <br />
          7.2.6 заперечення проти Опрацювання Персональних даних згідно зі ст.
          21 GDPR;
          <br />
          7.2.7 заперечення проти автоматизованої обробки (за наявності такої)
          Персональних даних згідно зі ст. 22 GDPR;
          <br />
          7.2.8 відкликання своєї Згоди на Обробку Персональних даних, якщо це
          застосовується згідно зі ст. 7 (3) GDPR - ви можете відкликати свою
          згоду на обробку та використання ваших персональних даних у будь-який
          час. Зверніть увагу, що у разі відкликання Згоди, ви більше не зможете
          скористатися Сервісами RADIODIM.RV.UA, що потребують використання
          Ваших Персональних даних;
          <br />
          7.2.9 подання скарги до вашого національного наглядового органу згідно
          зі ст. 13(2)(d), 14(2)(e), 15(1)(f) GDPR, якщо ви вважаєте, що ваші
          права на конфіденційність були порушені;
          <br />
          7.2.10 отримання інформації про Персональні дані згідно з CCPA, які ми
          зібрали про вас за останні 12 місяців. Ви можете подати такий запит не
          більше ніж 2 рази протягом 12 місяців.
          <br />
          7.3 Якщо ви попросите нас виправити, стерти особисті дані або
          обмежити, припинити обробку або відкликати вашу згоду на обробку
          персональних даних, ми повідомимо вас, як тільки ваш запит буде
          задоволений, відповідно до ст.13(2)(c), 14(2) )(d) та 19 GDPR.
          <br />
          7.4 Якщо ваш запит не вирішено або вирішено незадовільно, ви маєте
          право звернутися до місцевого органу захисту даних згідно зі ст.
          13(2)(d), 14(2)(e), 15(1)(f) GDPR.
          <br />
          7.5 Ви маєте право увійти до свого Профілю та змінити інформацію про
          себе такою мірою, якою це дозволяє система. Також ви можете подати
          запит на зміну інформації про себе електронною поштою:
          ad.radiodim@gmail.com
          <br />
          7.6 Якщо ви бажаєте подати запит на видалення Ваших персональних даних
          відповідно до вимог GDPR, надішліть повідомлення на електрону пошту
          ad.radiodim@gmail.com. При подачі запиту електронною поштою вас
          попросять надати інформацію для ідентифікації та перевірки.
        </p>
        <h3 className="mt-5 mb-2">8. COOKIES І АНАЛОГІЧНІ ТЕХНОЛОГІЇ</h3>
        <p className="mt-2 mb-4 mx-5">
          Наш веб-сайт використовує файли-cookies. Cookie — це файл, що містить
          ідентифікатор (стрічку, яка складається з літер та цифр), і який
          надсилається веб-сервером до веб-браузера, та зберігається браузером.
          У подальшому, ідентифікатор надсилається назад на сервер кожного разу,
          коли браузер запитує веб-сторінку з сервера. Файли-cookies можуть бути
          або «постійними» або «сеансовими»: постійні будуть зберігатися
          браузером та будуть придатними до завершення терміну дії, якщо тільки
          не будуть видалені користувачем завчасно; «сеансові» навпаки – будуть
          видалятися після завершення сеансу роботи з сайтом або після закриття
          браузера. Файли-cookies зазвичай не містять жодної інформації, яка
          ідентифікує особу користувача. Проте, ваша персональна інформація, яку
          ми маємо, може бути пов’язана з інформацією, що зберігається та
          отримана від файлів-cookies. На нашому веб-сайті ми використовуємо як
          постійні, так і сеансові файли-cookies.
          <br />
          Назви файлів-cookies, які ми використовуємо на нашому веб-сайті, та
          цілі, задля яких вони використовуються, зазначені нижче:
          <br />
          На нашому веб-сайті ми використовуємо Google Analytics та Adwords для
          розпізнавання комп’ютера, мобільного терміналу, коли користувач
          відвідує веб-сайт, користується мобільним застосунком РАДІОДІМ /
          відстеження навігації користувачів по веб-сайту / вдосконалення
          зручності користування сайтом / аналіз використання веб-сайту /
          адміністрування сайту / попередження шахрайства та вдосконалення
          безпеки сайту / цільової реклами, яка може бути цікавою користувачам /
          відстеження навігації користувачів по меню мобільного застосунка /
          вдосконалення зручності користування мобільним застосунком / аналізу
          використання мобільного застосунка / адміністрування мобільного
          застосунка.
          <br />
          Більшість браузерів надають вам можливість відмовитися від
          використання файлів-cookies, наприклад:
          <br />
          1. в Internet Explorer (версія 10) ви можете заблокувати
          використовуючи налаштування керування файлами-cookie, доступними в
          меню «Інструменти» – «Опції Інтернету» – «Конфіденційність» –
          «Розширені» ( “Tools,” “Internet Options,” “Privacy,” “Advanced”);
          <br />
          2. у Firefox (версія 24) ви можете заблокувати всі файли-cookie,
          натиснувши «Інструменти» – «Опції» – «Конфіденційність»: у випадаючому
          меню оберіть пункт «Використовувати користувацькі налаштування
          журналу» та зніміть виділення з пункту «Прийняти файли-cookie від
          сайтів»; та нарешті
          <br />
          3. в Chrome (версія 29) ви можете заблокувати всі файли-cookie
          увійшовши до меню «Налаштування та управління», та обравши
          «Налаштування» – «Відобразити розширені налаштування» та «Налаштування
          контенту», а потім обравши «Заборонити сайтам надсилати будь-які дані»
          під заголовком «Cookies».
          <br />
          Блокування всіх файлів-cookiе матиме негативні наслідки на зручність
          користування багатьма веб-сайтами. Якщо ви заблокуєте файли-cookie, ви
          не зможете користуватися багатьма функціями нашого веб-сайту.
          <br />
          Ви можете видалити файли-cookie, які вже зберігаються на вашому
          комп’ютері, наприклад:
          <br />
          1. в Internet Explorer (версія 10), ви маєте видаляти файли-cookie
          вручну (інструкцію, як це зробити, можна знайти за адресою
          http://support.microsoft.com/kb/278835 );
          <br />
          2. у Firefox (версія 24), файли-cookie можна видалити перейшовши в
          меню «Інструменти» – «Опції» – «Конфіденційність»: у випадаючому меню
          оберіть пункт «Використовувати користувацькі налаштування журналу»,
          натисніть “Показати Cookies,” а потім – “Видалити всі Cookies”;
          <br />
          3. в Chrome (версія 29) ви можете видалити всі файли-cookie увійшовши
          до меню «Налаштування та управління», та обравши «Налаштування» –
          «Відобразити розширені налаштування» та «Очистити дані перегляду», а
          перед тим оберіть пункт «Видалити файли-cookie та інші дані й плагіни
          сайтів».
        </p>
        <h3 className="mt-5 mb-2">9. КОНФІДЕНЦІЙНІСТЬ ДІТЕЙ (Стаття 8 GDPR)</h3>
        <p className="mt-2 mb-4 mx-5">
        9.1 Ми приділяємо велику увагу забезпеченню конфіденційності дітей. Сервіси RADIODIM.RV.UA не призначені для використання дітьми віком до 18 років, без явного дозволу на використання від батьків або опікуна.
<br/>9.2 Якщо вам не виповнилося 18 років, вам потрібно отримати дозвіл батьків/опікуна, перш ніж надсилати будь-які персональні дані в RADIODIM.RV.UA. Ми можемо обробляти Персональні дані дітей до 18 років за умови, що Персональні дані надані батьками/законними опікунами за погодженням з явною метою використання Сервісів.
<br/>9.3 RADIODIM.RV.UA діє відповідно до Закону про захист конфіденційності дітей в Інтернеті (COPPA). Цей закон вимагає від нас інформування батьків та законних опікунів про те, як ми збираємо, використовуємо та розкриваємо особисту інформацію дітей віком до 13 років.
<br/>9.4 Ми ніколи свідомо не збираємо, не обробляємо і не просимо будь-яку інформацію в осіб, молодших 18 років.

        </p>
        <h3 className="mt-5 mb-2">10. ІНШІ ЗОБОВ'ЯЗАННЯ, ПОВ'ЯЗАНІ З КОНФІДЕНЦІЙНОСТЬЮ</h3>
        <p className="mt-2 mb-4 mx-5">
        10.1 Ми будемо збирати та використовувати ваші Дані лише у тих випадках, коли у нас є на це законні підстави.
<br/>10.2 Ми завжди будемо діяти прозоро та розповімо вам про те, як ми використовуємо вашу інформацію. Ми будемо дотримуватись і поважати ваші права, забезпечуючи швидкий та прозорий розгляд запитів, пов'язаних з питаннями конфіденційності.
<br/>10.3 Ми не будемо запитувати більше даних, ніж це необхідно для надання наших послуг.
<br/>10.4 Якщо будь-які ваші права стосовно ваших персональних даних будуть порушені, ми негайно повідомимо вас і відповідні органи захисту даних про такі випадки, якщо існує високий ризик порушення ваших прав, як Суб'єкта даних. Ми також зробимо все можливе, щоб мінімізувати такі ризики.
<br/>10.5 Ми також забезпечимо, щоб усі наші обробники даних мали відповідні заходи безпеки, а також договірні положення, що вимагають від них дотримання наших зобов'язань.


        </p>
        <h3 className="mt-5 mb-2">11. ПИТАННЯ ЮРИДИЧНОГО ХАРАКТЕРУ</h3>
        <p className="mt-2 mb-4 mx-5">
        11.1 RADIODIM.RV.UA вважає використання вами Сервісів вашим особистим рішенням і вашою особистою справою. Однак ми можемо розкривати ваші персональні дані, що зберігаються в наших базах даних, з метою:
<br/>11.1.1 дотримання законодавства або судового рішення, врученого нам;
<br/>11.1.2 застосування та розслідування можливих порушень цієї Політики конфіденційності;
<br/>11.1.3 розслідування потенційних шахрайських дій;
<br/>11.1.4 захисту прав, власності чи безпеки RADIODIM.RV.UA, його співробітників, Користувачів чи громадськості.

        </p>
        <h3 className="mt-5 mb-2">12. ГАРАНТІЇ ТА ВІДПОВІДАЛЬНІСТЬ</h3>
        <p className="mt-2 mb-4 mx-5">
        12.1 Ми не гарантуємо безпомилкову роботу Сервісів відповідно до цієї Політики конфіденційності. RADIODIM.RV.UA докладе розумних зусиль для дотримання Політики конфіденційності та здійснить негайні коригувальні дії, у разі порушення або недотримання нашої Політики конфіденційності.
<br/>12.2 RADIODIM.RV.UA не несе відповідальності за будь-які випадкові, непрямі збитки або штрафи, пов'язані з цією Політикою конфіденційності.
<br/>12.3 Ми не контролюємо сторонні веб-сайти та не несемо відповідальності за їхні заяви про конфіденційність. Ми явно відмовляємось від відповідальності за їх зміст, політику конфіденційності та умови використання і не робимо жодних заяв щодо їх точності, змісту чи ретельності.
<br/>12.4 У випадках зміни або оновлення Політики конфіденційності, RADIODIM.RV.UA завжди буде повідомляти вас на нашому Сайті і вказуватиме дату оновлення.


        </p>
      </header>
    </div>
  );
};
export default Policy;
